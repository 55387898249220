export default {
  message: {
    home: 'Home',
    login: 'Log In',
    accountLogin: 'Account Log In',
    pleaseEnterAccountName: 'Account Name',
    pleaseEnterYourPassword: 'Password',
    otherMethods: 'Other Methods',
    exitSystem: 'Logout',
    reminder: 'Reminder',
    exitSystemTips: 'Are you sure to logout?',
    pleaseEnterNamePassword: 'Please enter the correct user name and password',

    // menu
    wordCloud: 'Word Cloud',
    commentRanking: 'EC Comment',
    setup: 'Settings',
    setVocabulary: 'Set Stopwords',

     // 词云
    selectTime: 'Please select the time period',
    wordcloudClickSearchGetData: 'Please click the search button to query data',
    wordcloudNoDataFound: 'No data found for the given parameters',
    wordcloudHeadwordSymbols: 'Special symbols exist in Center Word, please remove it',
    wordcloudNotSupportSymbols: 'Special symbols ara not supported. Only words or digits are supported.',
    wordcloudHeadword: 'Keyword',
    wordcloudPleaseFillOut: 'Optional',
    wordcloudPlatform: 'Social Platform',
    wordcloudPleaseChoose: 'Please choose',
    wordcloudSelectAll: 'All',
    wordcloudBrand: 'Brand',
    wordcloudPeriod: 'Time Period',
    wordcloudStartingTime: 'Start Date',
    wordcloudEndTime: 'End Date',
    wordcloudNumberWords: 'Number of Words',
    reset: 'Reset',
    search: 'Search',
    keywords: 'Keyword',

    // 评论
    ECProductIDsTips: 'Please use comma between given product IDs',
    ECProductIDsTipsReg: 'Only digits are supported in product IDs (Please use comma to split IDs.)',
    new: 'Create New',
    filterWord: 'Stopwords',
    username: 'Creator',
    date: 'Create Time',
    actions: "Actions",
    delete: 'Delete',

    addFilter: 'Create a new stopword',
    "confirm": "Confirm",
    "cancel": "Cancel",
    deleteConfirm: 'Confirm to delete',
    deleteConfirmTips: 'Are you sure to delete?',
    clickQueryData:'Click the Search button to query the data',
    original:'original text',
    heat:'degree of heat',

    
    filter:'Exclude official account',
    details: 'Click for details',
    comment: 'comments',
    sort: 'classification',
    contact: 'contact us',
    numLimit: 'Please select a number between 1-100',
    placeholderNum: 'Please enter number of words',
    buzz: 'Buzz',
    productCode: 'Product Code',
    brand: 'Brand',
    productDesc: 'Product Desc',
    category: 'Category',
    SBU: 'SBU',
    year: 'Year',
    season: 'Season',
    gender: 'Gender',
    color: 'Color',
    colorGroup: 'Color Group',
    score: 'Score',
    recommendedNumber: 'recommended number',
    intelligentRecommendation: 'Intelligent Recommendation',
    selectProduct: 'Please select at least one product',
    recommend: 'Recommend',
    pleaseInput: 'Please Input',
    copywriting: 'Copywriting',

    // 文案
    activityContent: 'Activity',
    productName: 'Product Name',
    productNamePlaceholder: 'Product name description',
    eventTheme: 'Theme',
    eventThemePlaceholder: 'Activity theme description',
    productFeatures: 'Product Trait',
    productFeaturesPlaceholder: 'Other product features (limited to 50 words)',
    targetAudience: 'Target Group',
    populationAge: 'Age',
    populationAgePlaceholder: 'Value or Description',
    crowdInterests: 'Interests',
    crowdInterestsPlaceholder: 'Group interest (limited to 50 words)',
    otherFeatures: 'Other Trait',
    otherFeaturesPlaceholder: 'Other demographic characteristics (limited to 50 words)',
    articleRequirements: 'Content Style',
    copywritingTone: 'Tone',
    wordNumber: 'Word Count',
    wordNumberPlaceholder: 'Up to 1000 words',
    deliveryChannels: 'Platform',
    generateContent: 'Generate Content',
    generateTokenNumber: 'Total token used',
    modificationComments: 'Revision Description',
    modificationCommentsPlaceholder: 'Input limited to 100 words',
    adjustingContent: 'Adjust Content',
    lively: 'Lively',
    amusing: 'Amusing',
    official: 'Official',
    polite: 'Polite',
    casual: 'Casual',
    introverted: 'Introverted',
    passionate: 'Passionate',
    literary: 'Literary',
    solemn: 'Solemn',
    male: 'Male',
    female: 'Female',
    unlimited: 'Unlimited',
    copywritingTips: 'Do not enter any company confidential information. If you have any concern, you can click on the problem feedback button in the upper right corner. Thank you!',
    copywritingTips2: 'Do not disclose any Personal Information about an employee, contractor, consumers and etc.',
    copywritingTips3: 'Do not upload any VF Intellectual Property or create any new material using existing VF Intellectual Property.',
    redbook: 'RedBook',
    weibo: 'Weibo',
    officialAccount: 'WeChat Official Account',
    textMessage: 'Text Message',
    wecom: 'WeCom',
    advertisement: 'Advertisement',
    shortvideo: 'Short Video',
    creativeWriting: 'Creative Writing',
    instagram: 'Instagram',
    facebook: 'Facebook',
    livestream: 'Live Stream',
    waitingMessage: 'Generating in progress',

    // 问题反馈
    feedbackButton: 'Feedback',
    feedbackPlaceholder: 'Please input any issues encountered during use or desired features, and we will handle them as soon as we receive them.',
    select: 'Please select',
    "submitNow": "Submit",
    getAuthenticationUrl: "Getting Authentication Link.",
    customLogin: "Authenticating User.",
    authenticateFail: "Authentication failed, return to login page soon.",

    SEOOpti: 'SEO Optimization',
    hotWords: 'Hot Words',
    seoHotWordsPlaceholder: 'Can input SEO hot words, multiple words separated by commas',
    refPicture: 'Reference Image',
    translate: 'Translate',
    translateInputPlaceholder: 'Enter original text',
    translateFromLanguage: 'Source Language',
    translateToLanguage: 'Target Language',
    translateTone: 'Tone',
    translateAuto: 'Auto',
    translateDocUpload: 'Upload Document support format: .pptx,.docx,.pdf,.xlsx,.csv,.txt',
    translateDocDownload: 'Document Translation History',
    translateDocUploadBt: 'Upload Document',
    translateDocDownloadBt: 'Document Records',
    noDate: 'No Data',
    question: 'Question',
    questionInputPlaceholder: 'Enter the question asked',
    imageInputPlaceholder: 'Enter a description',
    "downloadAll": "Download All",
    imageWaitingMessage: 'Generating image, please wait',
    downloadImages: 'Download Images',
    image: 'images',
    askQuestion: 'Question',
    imageGeneration: 'Image Generation',

    batchCopyGenerationTemplate: 'Copywriting Generation Template',
    templateDownload: 'Template Download',
    upload: 'Upload',
    generateTemplatesBatchTranslation: 'Translation Generation Template',
    batchTips:'Batch tasks will only be kept for 1 year, please download them in a timely manner',
    taskName: 'Task Name',
    filePath: 'File Path',
    number: 'No.',
    taskTyps: 'Task Type',
    creator: 'Created By',
    creationTime: 'Create Time',
    taskStatus: 'Task Status',
    download: 'Download',
    refresh: 'Refresh',
    pleaseEnterName: 'Please enter a name',
    uploadCopywriting: 'Upload Copywriting',
    uploadTranslation: 'Upload Translation',
    batchTasks: 'Batch Tasks',
    choose: 'Choose',
    translation: 'Translation',
    inProgress: 'In progress',
    error: 'Error',
    completed: 'Completed',

    knowledge: 'Knowledge Base',
    searchResult: 'Found the following relevant results',
    relatedContentLink: 'Related source content',
    searchSummary: 'The answer is',

    //Chat Assistant
    chatAssistant:'Chat Assistant',
    send:'Send',
    topic:'Topic',
    chatnotice1:'New Chat session started',
    chatnotice2:'Previous messages won‘t be used as context for new queries',
    uploadDocument:'Upload Document',
    inputDocument:'Please input keywords to search',
    uploadFileName:'Search by File Name',
    uploadTips: "A file with the same name already exists. Uploading this file will overwrite the original file.",
    
    modelTips: "When translating into Japanese or Korean, you can choice the GPT4o1 model (switch in the upper right corner of the page) to achieve better quality."
  }
}
