
import { IFeedbackParams, ITranslateParams, ITranslateResponse, IImageParams, IImageResponse ,IKnowledgeParams,IKnowledgeResponse} from "~/interface/common";
import { get, post, postform, postStream } from "~/utils/http";
import { serviceUrlConfig } from "./serviceUrlConfig";

/**
 * @description: 词云
 * @param {ILoginParams} params 
 * @return {*}
 */
 export async function queryWordcloud(params: Object | undefined) {
  const url = serviceUrlConfig.queryWordcloud;
  const res:any = await get(url, params);
  return res 
 }

/**
 * @description: 评论
 * @param {ILoginParams} params 
 * @return {*}
 */
export async function queryBarchart(params: Object | undefined) {
  const url = serviceUrlConfig.queryBarchart;
  const res:any = await get(url, params);
  return res 
}
export async function queryComment(params: Object | undefined) {
  const url = serviceUrlConfig.queryComment;
  const res:any = await get(url, params);
  return res 
}
/**
 * @description: 品牌声量
 * @param {ILoginParams} params 
 * @return {*}
 */
export async function queryBuzz(params: Object | undefined) {
  const url = serviceUrlConfig.queryBuzz;
  const res:any = await get(url, params);
  return res 
 }

/**
 * @description: 新增过滤词
 * @param {Object} params
 * @return {*}
 */
export async function queryStopwords(params: Object | undefined) {
  const url = serviceUrlConfig.queryStopwords;
  const res:any = await get(url, params);
  return res 
}

/**
 * @description: 饼图
 * @param {*} params
 * @return {*}
 */
export async function queryPie(params: Object | undefined) {
  const url = serviceUrlConfig.queryPie;
  const res:any = await get(url, params);
  return res 
}

/**
 * @description: 获取时间
 * @param {*}
 * @return {*}
 */
export async function queryDate(params: Object | undefined) {
  const url = serviceUrlConfig.queryDate;
  const res:any = await get(url, params);
  return res 
}

/**
 * @description: 搜索条件模糊查询
 * @param {*}
 * @return {*}
 */
export async function querySearch(params: Object | undefined) {
  const url = serviceUrlConfig.querySearch;
  const res:any = await get(url, params);
  return res 
}

/**
 * @description: 产品查询
 * @param {*}
 * @return {*}
 */
export async function queryProduct(params: Object | undefined) {
  const url = serviceUrlConfig.queryProduct;
  const res:any = await get(url, params);
  return res 
}

/**
 * @description: 智能推荐产品查询
 * @param {*}
 * @return {*}
 */
export async function queryRecommandProduct(params: Object | undefined) {
  const url = serviceUrlConfig.queryRecommandProduct;
  const res:any = await get(url, params);
  return res 
}

/**
 * @description: 文案生成
 * @param {*}
 * @return {*}
 */
export async function copywritingGeneration(form: Object | undefined) {
  const url = serviceUrlConfig.copywritingGeneration;
  const res:any = await postform(url, form);
  return res 
}

/**
 * @description: 文案修改
 * @param {*}
 * @return {*}
 */
export async function copywritingRewrite(params: Object | undefined) {
  const url = serviceUrlConfig.copywritingRewrite;
  const res:any = await post(url, params);
  return res 
}

/**
 * @description: 问题反馈
 * @param {*}
 * @return {*}
 */
export async function feedback(params: IFeedbackParams) {
  const url = serviceUrlConfig.feedback;
  const res:any = await post(url, params);
  return res
}

// 翻译
export async function translate(params: ITranslateParams) {
  const url = serviceUrlConfig.translate;
  const res = await post(url, params);
  return res as {
    data: ITranslateResponse
  }
}

export async function translateStream(params: ITranslateParams) {
  const url = serviceUrlConfig.translateStream;
  const res = await postStream(url, params);
  return res as unknown as Response
}

export async function translateDocDownload(params: Object | undefined) {
  const url = serviceUrlConfig.translateDocDownload;
  const res:any = await get(url, params);
  return res 
}

export async function translateDocGetList(params: Object | undefined) {
  const url = serviceUrlConfig.translateDocList;
  const res:any = await get(url, params);
  return res 
}

export async function translateDocUpload(params: Object | undefined) {
  const url = serviceUrlConfig.translateDocUpload;
  const res:any = await post(url, params);
  return res 
}
export async function getOSSToken(params: Object | undefined) {
  const url = serviceUrlConfig.translateDocUploadSts;
  const res:any = await get(url, params);
  return res 
}

export async function translateSubmitUpload(params: Object | undefined) {
  const url = serviceUrlConfig.translateSubmitUpload;
  const res:any = await get(url, params);
  return res 
}


// 提问
export async function chat(params:IFeedbackParams) {
  const url = serviceUrlConfig.chat;
  const res = await post(url, params);
  return res as {
    data: ITranslateResponse
  }
}

// 流式提问
export async function chatStream(params:IFeedbackParams) {
  const url = serviceUrlConfig.chatStream;
  const res = await postStream(url, params);
  return res as unknown as Response
}

// 图片生成
export async function image(params:IImageParams) {
  const url = serviceUrlConfig.image;
  const res = await post(url, params);
  return res as {
    data: IImageResponse
  }
}
// 知识库
export async function knowledgeSearch(params:IKnowledgeParams) {
  const url = serviceUrlConfig.csSearch;
  const res = await post(url, params);
  return res as {
    data:IKnowledgeResponse
  }
}

// chatassistant
export async function chatSearch(params:IKnowledgeParams) {
  const url = serviceUrlConfig.csSearch;
  const res = await post(url, params);
  return res as {
    data:IKnowledgeResponse
  }
}

// 知识库topic
export async function searchTopic(params: Object | undefined) {
  const url = serviceUrlConfig.userTopicList;
  const res:any = await get(url, params);
  return res
}


