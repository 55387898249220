import axios from 'axios'
import qs from 'qs'
import { hideScreenLoading, showScreenLoading } from '../loading';
import { ElMessageError } from '../showElMessage';
import { notification } from '../showElNotification';
import { rmToken, rmUserInfo } from "~/utils/auth";
import { router } from "~/router";

// 全局设置 axios 发送请求带上cookie
axios.defaults.withCredentials = true;
axios.defaults.timeout = 300000;
const { VITE_API: api, VITE_HTTP_URL } = import.meta.env
axios.defaults.baseURL = `${VITE_HTTP_URL}` 

function dataType(data: any) {
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase()
}

// 请求拦截器
axios.interceptors.request.use((config) => {
  const handleConfig = { ...config };
  // showScreenLoading()
  handleConfig.transformRequest = [function (data) {
    if (dataType(handleConfig.data) === 'formdata') {
      // 去除post请求默认的Content-Type
      delete handleConfig?.headers?.post['Content-Type']
    } else {
      data = JSON.stringify(data)
    }
    return data
  }]

  if (handleConfig.headers) {
    handleConfig.headers['Access-Control-Allow-Origin'] = '*';
    handleConfig.headers['Content-Type'] = 'application/json'
  }
  
  return handleConfig
}, (error) => {
  return Promise.reject(error)
})

async function logOut() {
  try {
    rmUserInfo()
    rmToken()
    router.push({ path: '/login' })
  } catch (error) {
  }
}

// 响应拦截器                                                                           
axios.interceptors.response.use((res) => {
  // hideScreenLoading()
  if (res.status && res.status === 200) {
    // 数据请求成功
    
  }
  return res
}, (error) => {
  // hideScreenLoading()
  if (!error.response && error.message) {
    ElMessageError('请求超时，请检查网络，刷新后重试')
  } else {
    if (error.response.status === 401) {
      logOut()
    }
    else if (error.response.data.error) {
      ElMessageError(error.response.data.error)
    }
  }
  return Promise.reject(error)
})
export default axios

/**
* 封装get方法
* @param url
* @param params
* @returns {Promise}
*/

export function get (url: any, params: Object = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
* 封装post请求
* @param url
* @param data
* @returns {Promise}
*/

export function post(url: any, data: Object = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        resolve(response)
      }, err => {
        resolve(err)
        // reject(err)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
* 封装post请求
* @param url
* @param data
* @returns {Promise}
*/

export function postform(url: any, form: Object = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        resolve(response)
      }, err => {
        resolve(err)
        // reject(err)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
* 封装delete方法
* @param url
* @param params
* @returns {Promise}
*/

export function doDelete (url: any, params: Object = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      params: params
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/

export function patch (url: any, data: Object = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/

export function put (url: any, data: Object = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data,{
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export async function postStream(url: any, data: Object = {}) {
  return new Promise((resolve, reject) => {
    fetch(`${VITE_HTTP_URL}/${url}`, {  
        method: 'POST',  
        headers: {  
          'Content-Type': 'application/json'  
        },  
        body: JSON.stringify(data)  
    })
      .then(response => {
        if (response.status === 401) {
          logOut();
          reject(new Error('Unauthorized'));
        } else {
          resolve(response);
        }
      }, err => {
        resolve(err)
      })
      .catch(err => {
        reject(err)
      })
  })
}
