export default {
  message: {
    home:'홈',
    login:'로그인',
    accountLogin:'계정 로그인',
    pleaseEnterAccountName:'계정 이름을 입력하십시오',
    pleaseEnterYourPassword:'로그인 암호를 입력하십시오',
    exitSystem:'시스템 종료',
    reminder:'훈훈한 힌트',
    exitSystemTips:'시스템을 종료하시겠습니까?',
    pleaseEnterNamePassword:'정확한 사용자 이름과 비밀번호를 입력하십시오',

    // menu
    wordCloud:'사운',
    commentRanking:'댓글 순위',
    setup:'설정',
    setVocabulary:'어휘 설정',

    // word cloud
    selectTime:'시간을 먼저 선택하십시오',
    wordcloudClickSearchGetData:'검색을 클릭하여 데이터를 얻으십시오',
    wordcloudNoDataFound:'데이터가 검색되지 않았습니다',
    wordcloudHeadwordSymbols:'중심 단어는 특수 기호를 포함하므로 먼저 삭제하십시오',
    wordcloudNotSupportSymbols:'특수 기호 입력은 지원되지 않습니다. 중국어, 영어, 숫자만 입력할 수 있습니다',
    wordcloudHeadword:'중심 단어',
    wordcloudPleaseFillOut:'작성해주세요',
    wordcloudPlatform:'플랫폼',
    wordcloudPleaseChoose:'선택하세요',
    wordcloudSelectAll:'모두 선택',
    wordcloudBrand:'브랜드',
    wordcloudPeriod:'시간',
    wordcloudStartingTime:'시작 시간',
    wordcloudEndTime:'종료 시간',
    wordcloudNumberWords:'단어 수',
    reset:'재설정',
    search:'검색',
    keywords:'키워드',

    // comment
    ECProductIDsTips:'입력한 ids를 쉼표로 구분',
    ECProductIDsTipsReg:'입력한 id를 쉼표로 구분하여 숫자만 입력하는 id 지원',
    new:'신규',
    filterWord:'필터 단어',
    username:'사용자 이름',
    date:'날짜',
    actions:"작업",
    delete:'삭제',
    addFilter:'필터 추가',
    "confirm": "확인",
    "cancel": "취소",
    deleteConfirm:'삭제 확인',
    deleteConfirmTips:'삭제하시겠습니까?',
    clickQueryData:'검색 단추를 눌러 데이터를 조회합니다',
    original:'원문',
    heat:'열도',
    filter:'공식 계정 필터링',
    details:'자세히 보기 클릭',
    comment:'댓글',
    sort:'분류',
    contact:'연락해주세요',
    numLimit:'1-100 사이에서 숫자를 선택하십시오',
    placeholderNum:'단어 수를 입력하십시오',
    buzz:'브랜드 성량',
    productCode:'제품 코드',
    brand:'브랜드',
    productDesc:'제품 설명',
    category:'품목',
    SBU: 'SBU',
    year:'연',
    season:'계절',
    gender:'성별',
    color:'색상',
    colorGroup:'색상 그룹',
    score:'점수',
    recommendedNumber:'추천 개수',
    intelligentRecommendation:'스마트 추천',
    selectProduct:'제품을 하나 이상 선택하십시오',
    recommend:'추천',
    pleaseInput:'입력하십시오',
    copywriting:'문안',

    // copywriting
    activityContent:'이벤트 콘텐츠',
    productName:'제품 이름',
    productNamePlaceholder:'제품 이름 설명',
    eventTheme:'이벤트 테마',
    eventThemePlaceholder:'이벤트 테마 설명',
    productFeatures:'제품 특징',
    productFeaturesPlaceholder:'기타 제품 특징(50자 이내)',
    targetAudience:'타겟 군중',
    populationAge:'군중 나이',
    populationAgePlaceholder:'수치 또는 설명',
    crowdInterests:'군중취미',
    crowdInterestsPlaceholder:'군중취미(50자 이내)',
    otherFeatures:'기타 피쳐',
    otherFeaturesPlaceholder:'기타 사용자 특성(50자 이내)',
    articleRequirements:'문장 요구사항',
    copywritingTone:'문안말투',
    wordNumber:'문안 글자 수',
    wordNumberPlaceholder:'많게는 1000자까지',
    deliveryChannels:'프로젝트 채널',
    generateContent:'콘텐츠 생성',
    generateTokenNumber:'token 수 생성',
    modificationComments:'의견 수정',
    modificationCommentsPlaceholder:'100자 이내로 입력 제한',
    adjustingContent:'컨텐트 조정',
    lively:'발랄해',
    amusing:'재밌다',
    official:'공식',
    polite: '사양',
    casual: '캐주얼',
    introverted:'내렴',
    passionate:'열정',
    literary:'문예',
    solemn:'장중',
    male:'남',
    female:'여',
    unlimited:'무제한',
    copywritingTips:'회사 기밀 정보를 입력하지 마십시오. 사용 중인 어떤 문제도 오른쪽 상단의 문제 피드백 버튼을 클릭하여 피드백을 할 수 있습니다. 감사합니다!',
    copywritingTips2:'직원, 계약자, 소비자 등에 대한 어떠한 개인 정보도 공개하지 마십시오',
    copywritingTips3:'VF 지적 재산권을 업로드하거나 기존 VF 지적 재산을 사용하여 새로운 자료를 만들지 마십시오',
    redbook: 'RedBook',
    weibo: 'Weibo',
    officialAccount: 'WeChat Official Account',
    textMessage: 'Text Message',
    wecom: 'WeCom',
    advertisement: 'Advertisement',
    shortvideo: 'Short Video',
    creativeWriting: 'Creative Writing',
    instagram: 'Instagram',
    facebook: 'Facebook',
    livestream: 'Live Stream',
    waitingMessage:'문안을 만드는 중입니다. 잠시만 기다려 주십시오',
    
    // feedback
    feedbackButton:'문제 피드백',
    feedbackPlaceholder:'사용 중에 발생한 문제나 원하는 기능을 입력하십시오. 받은 후에 가능한 한 빨리 처리하겠습니다',
    select:'선택하세요',
    submitNow: '커밋',
    getAuthenticationUrl:"인증 주소 가져오는 중",
    customLogin:'사용자 확인 중',
    authenticateFail: "라이센스 실패, 로그인 페이지로 돌아갑니다",
    SEOOpti:'SEO 최적화',
    hotWords:'뜨거운 단어',
    seoHotWordsPlaceholder:'SEO 핫 단어를 입력할 수 있으며 여러 단어를 쉼표로 구분합니다',
    refPicture: '참고 이미지',
    translate:'번역',
    translateInputPlaceholder:'원문 입력',
    translateFromLanguage: '원 언어',
    translateToLanguage: '목표 언어',
    translateTone: '말투',
    translateAuto: '자동 감지',
    translateDocUpload: '문서 업로드, 지원 포맷: .pptx,.docx,.pdf,.xlsx,.csv,.txt',
    translateDocDownload: '문서 번역 기록',
    translateDocUploadBt: '업로드',
    translateDocDownloadBt: '기록',
    noDate:'데이터 없음',
    question:'질문',
    questionInputPlaceholder:'문제 입력',
    imageInputPlaceholder:'설명 입력',
    "downloadAll": "클릭 한 번으로 다운로드",
    imageWaitingMessage:'그림 생성 중, 잠시만 기다려주세요',
    downloadImages:'그림 다운로드',
    image:'그림',
    askQuestion:'질문',
    imageGeneration: '그림 생성',
    
    batchCopyGenerationTemplate: '대량 문서 생성 템플릿',
    templateDownload: '템플릿 다운로드',
    upload: '업로드',
    generateTemplatesBatchTranslation: '대량 번역 생성 템플릿',
    batchTips:'대량 작업은 1년만 유지, 즉시 다운로드',
    taskName: '작업 이름',
    filePath: '파일 경로',
    number: '수',
    taskTyps: '작업 유형',
    creator: '작성자',
    creationTime: '생성 시간',
    taskStatus: '작업 상태',
    download: '다운로드',
    refresh: '새로 고침',
    pleaseEnterName: '이름을 입력하십시오.',
    uploadCopywriting: '문안을 올리다',
    uploadTranslation: '번역 업로드',
    batchTasks: '대량 작업',
    choose: '선택',
    translation: '번역',
    inProgress: '진행 중',
    error: '오류',
    completed: '완료',

    knowledge:'지식 퀴즈',
    searchResult:'아래 결과 찾기',
    relatedContentLink:'관련 내용 원문',
    searchSummary: '답변은 다음과 같습니다',

    //Chat Assistant
    chatAssistant:'봇 채팅',
    send:'전송',
    topic:"주제",

    chatnotice1:'새 대화가 시작되었습니다.',
    chatnotice2:'이전 대화 내용은 인용되지 않습니다.',
    //upload Document
     uploadDocument:'문서 업로드',
     inputDocument:'검색 키워드 입력',
     uploadFileName:'키워드',
     uploadTips:"동일한 이름의 파일이 이미 존재합니다. 이 파일을 업로드하면 원래 파일이 덮어씌워집니다.",

     modelTips: "번역을 일본어나 한국어로 하려면 GPT4o1 모델(페이지 오른쪽 상단에서 전환)을 사용하여 더 나은 품질을 얻을 수 있습니다."
  }
}

